// Step 1: Import React
import * as React from 'react'
import Layout from "../components/layout"

// Step 2: Define your component
const ContactPage = () => {
  return (
    <Layout pageTitle="contact">
      <p>
        This website is a compressed version of me. To get in touch with the real me, send an email to hi AT zainshah DOT me.
      </p>
    </Layout>
  )
}

// Step 3: Export your component
export default ContactPage